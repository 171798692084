/**
 * Created by hoa on 21/08/2017.
 */

define(['app'], (app) => {
  const locatorCard = () => {
    const component = {};

    const _config = {
      locatorCard: '[data-js-element=locatorCard_group]',
      wrapper: '[data-js-element=locatorCard_wrapper]',
      cardHeader: '[data-js-element=locatorCard_header]',
      content: '[data-js-element=locatorCard_content]',
      opened: 'locatorCard-open',
    };

    const _init = (element) => {
      component.element = element;
      component.setUpCardEvents();
      app.subscribe('_locatorCards', component.setUpCardEvents);
      app.subscribe('_locatorCards/toggleAccordions', component.subscribeToCards);
    };

    const _subscribeToCards = (currentTarget) => {
      component.openCard(currentTarget);
      component.anchorTop(currentTarget);
    };

    const _setUpCardEvents = () => {
      component.wrapper = document.querySelectorAll(component.config.wrapper);
      component.cardHeader = document.querySelectorAll(component.config.cardHeader);
      Array.from(component.cardHeader).forEach(el => {
        el.addEventListener('click', component.cardClicked, false);
      });
    };

    const _cardClicked = (e) => {
      e.preventDefault();
      component.currentTarget = e.currentTarget.parentElement;

      let markerAttr = app.element.getAttribute('data-latlong', component.currentTarget);
      let markerCoord = markerAttr.split(',');
      app.publish('_locator/locatorMarker', markerCoord);

      component.openCard(component.currentTarget);
      component.anchorTop(component.currentTarget);
    };

    const _openCard = (currentTarget) => {
      component.activeCard = document.querySelector('.locatorCard-open');
      component.contentElement = currentTarget.querySelector(component.config.content);
      component.cardHeader = currentTarget.querySelector(component.config.cardHeader);
      app.element.toggleClass(component.config.opened, currentTarget);
      app.element.setAttribute('aria-expanded', 'false', component.cardHeader);
      app.element.setAttribute('aria-hidden', 'true', component.contentElement);

      if (currentTarget !== component.activeCard) {
        app.element.setAttribute('aria-expanded', 'true', component.cardHeader);
        app.element.setAttribute('aria-hidden', 'false', component.contentElement);

        if (component.activeCard) {
          app.element.removeClass(component.config.opened, component.activeCard);
        }
      }
    };

    const _anchorTop = (currentTarget) => {
      let anchor = currentTarget.offsetTop;
      let locatorCardGroup = document.querySelector(component.config.locatorCard);
      app.element.animateTo(locatorCardGroup, anchor, 200);
    };

    component.config = _config;
    component.init = _init;
    component.subscribeToCards = _subscribeToCards;
    component.setUpCardEvents = _setUpCardEvents;
    component.cardClicked = _cardClicked;
    component.openCard = _openCard;
    component.anchorTop = _anchorTop;

    return component;
  };

  return locatorCard;
});
